@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }


@import 'master';

@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400,700&subset=cyrillic,latin-ext');

@font-face {
	font-family: 'Yanone Kaffeesatz';
	src: url("../fonts/YanoneKaffeesatz-Regular.otf"); }

* {
	outline-color: transparent; }

body {
	background-color: #f9f6ed;
	font-family: 'Yanone Kaffeesatz', sans-serif;
	letter-spacing: 0.3px;
	position: relative;
	h1, h2, h3 {
		font-family: 'Montserrat', sans-serif;
		color: #414042; }
	h2 {
		font-size: 32px;
		font-weight: 700; }
	h3 {
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 800;
		@media screen and (max-width: 767px) {
			margin-top: 45px; } }
	a {
		color: $primaryColor;
		&:hover, &:focus {
			text-decoration: none; } }

	header {
		position: fixed;
		width: 100%;
		z-index: 9999;
		#navbar {
			margin-bottom: 0;
			border-bottom: none;
			background-color: white;
			@media screen and (max-width: 767px) {
				height: 100px; }
			.container-fluid {
				background-color: white; } }
		.navbar-default {
			box-shadow: none;
			background-image: none;
			border-color: transparent;
			.navbar-toggle {
				margin-top: 20px;
				border-color: $primaryColor;
				border: 2px solid $primaryColor;
				.icon-bar {
					background-color: $primaryColor; }
				&:hover {
					background-color: $primaryColor;
					.icon-bar {
						background-color: white; } } }
			.navbar-nav {
				margin-left: -1px;
				>li {
					position: relative;
					>a {
						padding: 40px 15px;
						font-size: 20px;
						&:hover, &:focus {
							text-decoration: none;
							color: white; }
						@media screen and (max-width: 1270px) and (min-width: 768px) {
							padding: 25px 32px; } }
					&:after {
						background-image: url(../images/layout/menu-arrow.svg);
						height: 10px;
						content: " ";
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						position: absolute;
						width: 100%;
						display: none; }
					&:hover {
						background-color: $primaryColor;
						color: white;
						&:after {
							display: block; } } }
				.selected {
					background-color: $primaryColor;
					a {
						color: white; }
					&:after {
						display: block; } } } }
		.logo {
			margin-left: 0;
			margin: 0 auto;
			display: block;
			img {
				width: 160px;
				margin: 18px auto 0;
				display: block; } }

		.mobile-menu {
			display: none;
			.logo {
				width: 140px;
				margin: 20px auto;
				display: block;
				float: left;
				img {
					width: 140px;
					margin: 0; } }
			.navbar-nav {
				>li {
					position: relative;
					>a {
						padding: 20px 15px; } }
				.social-icons {
					display: flex;
					div {
						margin: 0 auto;
						a {
							padding: 0;
							float: left;
							width: 45px; } } } } }
		.desktop-menu, .tablet-menu {
			.clock {
				float: right;
				margin-top: 30px; } }
		.tablet-menu {
			display: none;
			#logo {
				float: left;
				img {
					margin: 20px auto; } } }
		@media screen and (max-width: 1270px) {
			.desktop-menu {
				display: none; }
			.tablet-menu {
				display: block; } }
		@media screen and (max-width: 767px) {
			.tablet-menu {
				display: none; }
			.mobile-menu {
				display: block; } } }
	.container-fluid {
		padding-right: 45px;
		padding-left: 45px;
		@media screen and (max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px; } }
	.clock {
		position: relative;
		line-height: 17px;
		i {
			background-image: url(../images/layout/icon-clock.svg);
			background-repeat: no-repeat;
			display: block;
			height: 33px;
			width: 33px;
			position: absolute;
			left: -40px;
			top: 1px; }
		span {
			font-size: 16px; }
		strong {
			font-size: 20px; } }
	main {
		position: relative;
		padding-top: 100px;
		@media screen and (max-width: 1270px) and (min-width: 768px) {
			padding-top: 167px; }
		.banner {
			position: relative;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: top center;
			img {
				width: 100%; }
			.mobile-image {
				display: none; }
			@media screen and (max-width: 767px) {
				.mobile-image {
					display: block; }
				.desktop-image {
					display: none; } }
			h1 {
				top: 23%;
				left: 10%;
				position: absolute;
				span {
					color: white;
					font-size: 60px;
					font-weight: 800;
					display: inline-block;
					text-transform: uppercase;
					padding: 15px;
					margin-bottom: 5px;
					@media screen and (max-width: 1200px) {
						font-size: 40px; }
					@media screen and (max-width: 767px) {
						font-size: 20px;
						display: block;
						padding: 20px;
						background-color: #414042;
						width: 100%;
						text-align: center; } }
				@media screen and (max-width: 767px) {
					top: 100%;
					width: 100%;
					left: 0;
					margin-top: 0; } }
			#scroller {
				margin: 0 auto;
				display: block;
				position: absolute;
				bottom: 30px;
				left: calc(50% - 20px);
				img {
					width: 40px; } } }
		#damska-elegancia-za-rozumne-ceny {
			background-image: url(../images/layout/img-bg-girls.jpg);
			min-height: 400px;
			background-size: contain;
			background-position: bottom right;
			background-repeat: no-repeat;
			#quotes {
				padding-left: 0;
				.slick-arrow {
					display: inline-block;
					height: 37px;
					width: 126px;
					background-color: white;
					z-index: 999;
					position: absolute;
					bottom: 0;
					background-size: 20px;
					background-repeat: no-repeat;
					background-image: url(../images/layout/arrow-right.svg);
					background-position: 96px center; }
				.slick-prev {
					right: 50.5%;
					transform: rotate(180deg); }
				.slick-next {
					left: 50.5%; } }
			h2 {
				margin-bottom: 50px; }
			p, .labels {
				font-size: 18px;
				line-height: 30px;
				color: #7b7b7b;
				text-align: center;
				letter-spacing: 0.2px; }
			.labels {
				margin-top: 40px; }
			.quote {
				font-size: 33px;
				margin-top: 75px;
				font-style: italic;
				line-height: 44px;
				color: #d0cbbb;
				&:after {
					content: " ";
					border-bottom: 1px solid #e0dcd0;
					display: block;
					width: 50px;
					height: 1px;
					margin: 24px auto 0; } }
			.author {
				font-family: 'Sacramento', cursive;
				display: block;
				text-align: center;
				font-size: 22px;
				color: #000000;
				margin: 25px 0 75px; } }

		#sortiment {
			background-color: white;
			padding: 45px 40px 90px;
			margin-top: 50px;
			border-bottom: 2px solid #ece9e2;
			@media screen and (max-width: 767px) {
				padding: 45px 15px 90px; }
			.small-symbol {
				width: 30px;
				margin: 50px auto;
				display: block; }
			h2 {
				margin-top: 0;
				margin-bottom: 50px; }
			p {
				font-size: 18px;
				line-height: 21px;
				color: #7b7b7b;
				text-align: center; }
			@media screen and (min-width: 1300px) {
				.row {
					width: 80%;
					margin: 0 auto; } }
			.gallery {
				a {
					position: relative;
					margin-left: 6px;
					margin-right: 6px;
					&:after {
						content: " ";
						width: 100%;
						height: 100%;
						bottom: 0;
						position: absolute;
						background-size: 80px;
						background-repeat: no-repeat;
						background-position: center;
						transition: .3s ease-in-out;
						-webkit-transition: .3s ease-in-out; }
					&:hover {
						&:after {
							background-color: rgba(219, 194, 125, .8);
							background-image: url(../images/layout/icon-zoom.svg); } } }
				img {
					width: 100%; }
				.slick-arrow {
					display: inline-block;
					height: 37px;
					width: 126px;
					background-color: $secondaryColor;
					position: absolute;
					bottom: -54px;
					background-size: 20px;
					background-repeat: no-repeat;
					background-image: url(../images/layout/arrow-right.svg);
					background-position: 96px center; }
				.slick-prev {
					right: calc(50% + 6px);
					transform: rotate(180deg); }
				.slick-next {
					left: calc(50% + 6px); } } }
		#predajna-damskej-mody {
			background-color: white;
			padding: 45px 40px 90px;
			margin-top: 50px;
			border-bottom: 2px solid #ece9e2;
			@media screen and (max-width: 767px) {
				padding: 45px 15px 90px; }
			.small-symbol {
				width: 30px;
				margin: 50px auto;
				display: block; }
			h2 {
				margin-top: 0;
				margin-bottom: 50px; }
			p {
				font-size: 18px;
				line-height: 30px;
				color: #7b7b7b;
				text-align: center; }
			.left-gallery {
				a {
					position: relative;
					display: block;
					margin-bottom: 17px;
					&:after {
						content: " ";
						width: 100%;
						height: 100%;
						display: block;
						bottom: 0;
						position: absolute;
						background-size: 80px;
						background-repeat: no-repeat;
						background-position: center;
						transition: .3s ease-in-out;
						-webkit-transition: .3s ease-in-out; }
					&:hover {
						&:after {
							background-color: rgba(219, 194, 125, .8);
							background-image: url(../images/layout/icon-zoom.svg); } }
					img {
						width: 100%; } } }
			.right-gallery {
				a {
					display: block;
					overflow: hidden;
					margin-bottom: 20px;
					position: relative;
					&:after {
						content: " ";
						width: 100%;
						height: 100%;
						display: block;
						bottom: 0;
						position: absolute;
						background-size: 80px;
						background-repeat: no-repeat;
						background-position: center;
						transition: .3s ease-in-out;
						-webkit-transition: .3s ease-in-out; }
					&:hover {
						&:after {
							background-color: rgba(219, 194, 125, .8);
							background-image: url(../images/layout/icon-zoom.svg); } }
					@media screen and (max-width: 767px) {
						max-height: 100%; } } } }

		#partneri {
			background-color: white;
			padding: 45px 40px 90px;
			margin-top: 50px;
			border-bottom: 2px solid #ece9e2;
			text-align: center;
			h2 {
				margin-top: 0;
				margin-bottom: 50px; }
			a {
				margin-right: 30px;
				transition: .2s;
				-webkit-transition: .2s;
				img {
					width: 130px; }
				&:last-child {
					margin-right: 0;
					margin-left: 30px;
					img {
						width: 160px; } }
				&:hover, &:focus {
					opacity: 0.5; } } } }
	.right-panel {
		width: 45px;
		position: fixed;
		right: 0;
		img {
			width: 100%; }
		@media screen and (max-width: 767px) {
			display: none; } }
	footer {
		background-color: white;
		padding: 30px 40px;
		margin-top: 50px;
		border-bottom: 2px solid #ece9e2;
		h3 {
			text-transform: initial; }
		@media screen and (max-width: 767px) {
			padding: 30px 15px; }
		.clock {
			left: 41px; }
		address {
			ul {
				padding-left: 0;
				li {
					color: #7b7b7b;
					font-size: 18px;
					list-style-type: none;
					a {
						font-size: 20px;
						color: $primaryColor; } } } }
		form {
			input, textarea {
				width: 100%;
				display: block;
				font-family: 'Yanone Kaffeesatz', sans-serif;
				font-size: 18px; }
			input[type="text"], input[type="email"] {
				border: none;
				border-bottom: 2px solid $primaryColor;
				padding: 18px 5px 5px; }
			textarea {
				padding: 10px;
				border: 2px solid $primaryColor;
				height: 135px;
				@media screen and (max-width: 991px) {
					margin-top: 43px; } }
			input[type="submit"] {
				width: 190px;
				height: 46px;
				background: $primaryColor;
				border: 2px solid $primaryColor;
				border-radius: 5px;
				float: right;
				text-transform: uppercase;
				color: white;
				font-size: 18px;
				padding-top: 5px;
				@media screen and (max-width: 425px) {
					width: 100%; } } } }
	.copyright {
		color: #7b7b7b;
		text-align: center;
		font-size: 14px;
		margin: 15px;
		font-family: 'Montserrat', sans-serif;
		a {
			color: #7b7b7b;
			&:hover, &:focus {
				color: $primaryColor; } } } }


/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }

.animation-element {
	opacity: 0;
	position: relative; }

.animation-element {

	&.slide-left {
		opacity: 0;
		-moz-transition: all 1500ms linear;
		-webkit-transition: all 1500ms linear;
		-o-transition: all 1500ms linear;
		transition: all 1500ms linear;
		-moz-transform: translate3d(-900px, 0px, 0px);
		-webkit-transform: translate3d(-900px, 0px, 0px);
		-o-transform: translate(-900px, 0px);
		-ms-transform: translate(-900px, 0px);
		transform: translate3d(-900px, 0px, 0px); }

	&.slide-right {
		opacity: 0;
		-moz-transition: all 1500ms linear;
		-webkit-transition: all 1500ms linear;
		-o-transition: all 1500ms linear;
		transition: all 1500ms linear; } }

.animation-element {
	&.in-view {
		opacity: 1;
		-moz-transform: translate3d(0px, 0px, 0px);
		-webkit-transform: translate3d(0px, 0px, 0px);
		-o-transform: translate(0px, 0px);
		-ms-transform: translate(0px, 0px);
		transform: translate3d(0px, 0px, 0px); } }

.gdpr_wrapp {
	position: fixed !important;
	background-color: rgba(0,0,0,.5);
	width: 100%;
	top: 0;
	z-index: 99999;
	width: 100%;
	height: 100vh;
	.container {
		background-color: #fff; } }
.static-content {
	background-color: #fff;
	padding: 45px 40px 90px;
	margin-top: 50px;
	border-bottom: 2px solid #ece9e2;
	p, li {
		font-size: 18px;
		line-height: 28px; }
	p {
		font-weight: 300; } }


@import 'footer';

@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';
@import 'default_javascript';
